import { render, staticRenderFns } from "./modal-prompt.vue?vue&type=template&id=9055d1ec&"
import script from "./modal-prompt.vue?vue&type=script&lang=js&"
export * from "./modal-prompt.vue?vue&type=script&lang=js&"
import style0 from "./modal-prompt.vue?vue&type=style&index=0&scss=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!D:/IIS/rentmagic.website.vue.vlaams-brabant/test/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BModal} from 'bootstrap-vue'
    installComponents(component, {BButton, BModal})
    

export default component.exports